import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout/index'
import SectionLancamentos from '../components/SectionLancamentos/sectionLancamentos'
import Swiper from 'react-id-swiper'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import './styles/produtos.scss'

// Assets
// import baby from '../images/produtos/image 87.png'
// import produtosNovo from '../images/produtos/imageProdutos.jpg'
// import grafismo from '../images/produtos/Grafismo.png'

// import produtosDia from '../images/home/header/produtos/Produtos.png'
// import bazar from '../images/home/header/produtos/Bazar.svg'
// import bebidas from '../images/home/header/produtos/bebidas.svg'
// import congelados from '../images/home/header/produtos/Carnes.svg'
// import cuidados from '../images/home/header/produtos/cuidados.svg'
// import importados from '../images/home/header/produtos/importados.svg'
// import limpeza from '../images/home/header/produtos/limpeza.svg'
// import mercearia from '../images/home/header/produtos/mercearia.svg'
// import cesta from '../images/home/header/produtos/cesta-basica.svg'

import emoji from '../images/produtos/emoji.svg'
import hand from '../images/produtos/hand.svg'
import mapa from '../images/produtos/Group 410.png'

import preco from '../images/produtos/preco.svg'
import confiancamelhor from '../images/produtos/confianca-melhor.svg'
// import manifesto from '../images/produtos/imagem_manifesto.png'
import logoMelhor from '../images/produtos/logo-melhor-cada-dia.svg'
import qualidade from '../images/produtos/qualidade.svg'
import pilares from '../images/produtos/pilares-da-marca.svg'

// Image Cards
// import Pipoca from '../images/produtos/Pipoca.png'
// import Fralda from '../images/produtos/Fralda.png'
// import Conserva from '../images/produtos/Conserva.png'
// import Barbeador from '../images/produtos/Barbeador.png'
// import produto1 from '../images/produtos/produtos1.png'
// import produto3 from '../images/produtos/produtos3.png'
// import mokochoko from '../images/produtos/MokoChoko.png'

const swiperParams = {
  spaceBetween: 1,
  slidesPerView: 1,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true
  },
  breakpoints: {
    550: {
      slidesPerView: 'auto',
      spaceBetween: 0
    },
    900: {
      slidesPerView: 'auto',
      spaceBetween: 0
    },
    1000: {
      slidesPerView: 'auto',
      spaceBetween: 0
    },
    1100: {
      slidesPerView: 'auto',
      spaceBetween: 0
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 0
    }
  }
}

const Produtos = ({ data }) => {
  const breadC = {
    visible: true,
    color: 'white',
    items: [
      { path: '/', name: 'Home' },
      { path: '/produtos/', name: 'Produtos DIA' }
    ]
  }

  // const fluid = {
  //   aspectRatio: 1,
  //   src: 'https://www.dia.com.br/cache/produtos/600x600/01898.jpg',
  //   srcWebp: 'https://www.dia.com.br/cache/produtos/600x600/01898.webp',
  //   srcSet: 'https://www.dia.com.br/cache/produtos/200x200/01898.jpg 200w, https://www.dia.com.br/cache/produtos/400x400/01898.jpg 400w,https://www.dia.com.br/cache/produtos/600x600/01898.jpg 600w,https://www.dia.com.br/cache/produtos/800x800/01898.jpg 800w,https://www.dia.com.br/cache/produtos/1000x1000/01898.jpg 1000w',
  //   srcSetWebp: 'https://www.dia.com.br/cache/produtos/200x200/01898.webp 200w,https://www.dia.com.br/cache/produtos/400x400/01898.webp 400w,https://www.dia.com.br/cache/produtos/600x600/01898.webp 600w,https://www.dia.com.br/cache/produtos/800x800/01898.webp 800w'
  // }

  return (
    <Layout breadCrumb={breadC}>
      <Helmet>
        <html lang="pt-BR" amp />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <title>Produtos - Dia Supermercados</title>
        <meta name="description" content="Somos a marca própria mais vendida no Brasil. Conheça os pilares da marca. Melhor a Cada DIA." />
        <meta name="keywords" content="produtos dia, marca propria" />
        <meta name="author" content="DIA Supermercados" />
      </Helmet>
      <section>
        <div className="bg-produtos">
          <div className="container mb-4 d-none d-lg-block">
            <div className="row">
              <div className="col-lg-6 position-relative">
                {/* <Img className="grafismo img-fuild" alt="grafismo do DIA - é garantia de qualidade" fluid={data.grafismo.childImageSharp.fluid}/> */}
                <div className="heightCustom"><h1 tabIndex={0} className="text-white position-absolute font-size-15">É GARANTIA DE QUALIDADE E PREÇO<br/> BAIXO QUE VOCÊ QUER? ENTÃO VOCÊ<br/> ESTÁ NO LUGAR CERTO!</h1></div>
                <p className="mt-5 ml-5 pl-5 pr-4 font-size-1">Aqui no Dia a gente pensa em tudo, principalmente em você e no seu bolso. Sabe por quê? </p>
                <p className="ml-5 pl-5 pr-4 font-size-1"><b>Porque sabemos o quanto é importante ter em casa produtos de qualidade garantida a
                  preços camaradas.</b> E, por isso, lotamos nossas prateleiras com mais de 1.000 itens de
                  diveeeeersas categorias – tem de tudo, pode acreditar! </p>
              </div>
              <div className="col-lg-5 mt-5 pt-5 align-items-end">
                <div className="mt-5 pt-3">
                  <GatsbyImage
                    image={data.tapiok.childImageSharp.gatsbyImageData}
                    className="dia-rounded mt-5 w-85"
                    alt="produto tapiok do DIA Supermercados" />
                </div>
              </div>
            </div>
          </div>
          <div className="container mb-4 d-block d-lg-none">
            <div className="row mb-4">
              <div className="position-relative col-md-6 h-25%">
                <div className="">
                  <GatsbyImage
                    image={data.grafismo.childImageSharp.gatsbyImageData}
                    className="grafismo img-fuild"
                    alt="grafismo do DIA - é garantia de qualidade" />
                  <h2 tabIndex={0} className=" h2-garantia text-white position-absolute font-size-2">É GARANTIA DE QUALIDADE E PREÇO<br/> BAIXO QUE VOCÊ QUER? ENTÃO VOCÊ<br/> ESTÁ NO LUGAR CERTO!</h2>
                </div>
              </div>
              <div className="col-md-6 pt-5">
                <div className="mx-3 px-2">
                  <GatsbyImage
                    image={data.tapiok.childImageSharp.gatsbyImageData}
                    className="custom-margin-top-produtos dia-rounded img-fluid"
                    alt="produto tapiok do DIA Supermercados" />
                  <p className=" diap mt-4 font-size-1125">É pensando sempre em você que oferecemos os produtos de Marca Própria DIA. São mais de mil tipos de itens das mais diversas categorias e que contam com preços significativamente mais baixos e qualidade garantida. </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container mb-3">
            <div className="row mx-lg-0 px-lg-0 px-2">
              <div className="col-md-6 d-flex justify-content-end align-self-start mb-3 mt-2">
                <div className="marca-propria d-flex flex-column align-items-start">
                  <img className="mb-3" alt="emoji coração sorriso" src={emoji}/>
                  <span className="text-white font-size-25 mb-3">Marca Própria nº 1<br/>do Brasil!</span>
                  {/* <a className=" text-white font-size-13">Saiba mais</a> */}
                  <img className="mapa position-absolute" alt="mapa brasil" src={mapa}/>
                </div>
              </div>
              <div className="col-md-6 mt-2">
                <GatsbyImage
                  image={data.mokochoko.childImageSharp.gatsbyImageData}
                  className="dia-rounded mb-4 img-fluid"
                  alt="Produto Moko Choko, achocolatado e sabor morango" />
              </div>
              <div className="col mb-3">
                <GatsbyImage
                  image={data.papelperfumado.childImageSharp.gatsbyImageData}
                  className="dia-rounded custom-margin-top-produtos img-fluid"
                  alt="papel higienico perfumado" />
              </div>
              <div className="col-md-6 mb-3 mt-2 mx-0">
                <div className="melhor-custo align-items-center justify-content-between dia-rounded px-lg-4 py-lg-4 py-3 px-5">
                  <img className="mb-3 d-block" alt="mão estendida com cifrão" src={hand}/>
                  <span className="text-white font-size-2 mb-3">CUSTO-BENEFÍCIO TOP!</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-red mb-5">
          <div className="container">
            <div id="swiperPilares">
              <div className="row pt-lg-5 justify-content-center d-flex align-items-center">
                <div className="col-lg-3 mt-3 pt-5 text-center d-lg-none d-flex">
                  <div className="col-6">
                    <img src={pilares} alt="Pilares" className="w-100 h-100"/>
                  </div>
                  <div className="col-6">
                    <img src={logoMelhor} alt="Logo Melhor a cada dia" className="mt-3 h-75 w-75" />
                  </div>
                </div>
                <Swiper {...swiperParams}>
                  <div className="col-lg-3 d-lg-block d-none pt-5 text-center">
                    <div className="col-lg-12">
                      <img src={pilares} alt="Pilares" />
                    </div>
                    <img src={logoMelhor} alt="Logo Melhor a cada dia" className="mt-5 pt-3" />
                  </div>
                  <div className="col-lg-3 pt-5 text-center">
                    <img src={confiancamelhor} alt="Confiança" width={214} height={265} />
                    <p className="text-center text-white mt-3 text-uppercase">Para que a gente possa estar sempre juntos, reconhecendo e atendendo às suas necessidades.</p>
                  </div>
                  <div className="col-lg-3 pt-5 text-center">
                    <img src={qualidade} alt="Qualidade" width={214} height={265}/>
                    <p className="text-center text-white mt-3 text-uppercase">Para que você possa levar sempre os produtos para a casa.</p>
                  </div>
                  <div className="col-lg-3 pt-5 text-center">
                    <img src={preco} alt="Preço" width={214} height={265}/>
                    <p className="text-center text-white mt-3 text-uppercase">Precinho pequeno e qualidade gigante. Porque queremos ser aquela escolha diária e que cabe no seu bolso.</p>
                  </div>
                </Swiper>
              </div>
            </div>
          </div>
        </div>

        <div className="container mb-5 p-0">
          <SectionLancamentos />
        </div>

        {/* <div className="container px-lg-0 mb-5 conheca-dia">
          <header className="pb-4 d-flex justify-content-center align-items-center">
            <h2 className="text-center text-red px-5">
              Conheça todos os produtos <span className="font-weight-bold">Dia</span>
            </h2>
          </header>
          <div className="row">
            <div className="col-6 col-lg mb-3 d-flex justify-content-center">
              <Link to='/produtos/mercearia' className="d-grid justify-content-center receitas-circle">
                <img src={mercearia} className="w-100 align-self-end" />
                <p className={'text-blue text-center font-size-1125 align-items-top font-weight-bold conheca-dia-text'} >Mercearia</p>
              </Link>
            </div>

            <div className="col-6 col-lg mb-3 d-flex justify-content-center">
              <Link to='/produtos/bebidas' className="d-grid justify-content-center receitas-circle">
                <img src={bebidas} className="w-100 align-self-end" />
                <p className={'text-blue text-center font-size-1125 align-items-top font-weight-bold conheca-dia-text'} >Bebidas</p>
              </Link>
            </div>

            <div className="col-6 col-lg mb-3 d-flex justify-content-center">
              <Link to='/produtos/congelados' className="d-grid justify-content-center receitas-circle">
                <img src={congelados} className="w-100 align-self-end" />
                <p className={'text-blue text-center font-size-1125 align-items-top font-weight-bold conheca-dia-text custom-bottom'} >Congelados <br/>e Frios</p>
              </Link>
            </div>

            <div className="col-6 col-lg mb-3 d-flex justify-content-center">
              <Link to='/produtos/limpeza' className="d-grid justify-content-center receitas-circle">
                <img src={limpeza} className="w-100 align-self-end" />
                <p className={'text-blue text-center font-size-1125 align-items-top font-weight-bold conheca-dia-text'} >Limpeza</p>
              </Link>
            </div>

            <div className="col-6 col-lg mb-3 d-flex justify-content-center">
              <Link to='/produtos/higiene' className="d-grid justify-content-center receitas-circle">
                <img src={cuidados} className="w-100 align-self-end" />
                <p className={'text-blue text-center font-size-1125 align-items-top font-weight-bold conheca-dia-text'} >Cuidados</p>
              </Link>
            </div>

            <div className="col-6 col-lg mb-3 d-flex justify-content-center">
              <Link to='/produtos/bazar-e-textil' className="d-grid justify-content-center receitas-circle">
                <img src={bazar} className="w-100 align-self-end" />
                <p className={'text-blue text-center font-size-1125 align-items-top font-weight-bold conheca-dia-text'} >Bazar e Têxtil</p>
              </Link>
            </div>

            <div className="col-6 col-lg mb-3 d-flex justify-content-center">
              <Link to='/produtos/cesta-basica' className="d-grid justify-content-center receitas-circle">
                <img src={cesta} className="w-100 align-self-end" />
                <p className={'text-blue text-center font-size-1125 align-items-top font-weight-bold conheca-dia-text font-Yanone'} >cesta-basica</p>
              </Link>
            </div>

            <div className="col-6 col-lg mb-3 d-flex justify-content-center">
              <Link to='/produtos/importados' className="d-grid justify-content-center receitas-circle">
                <img src={importados} className="w-100 align-self-end" />
                <p className={'text-blue text-center font-size-1125 align-items-top font-weight-bold conheca-dia-text font-Yanone'} >Importados</p>
              </Link>
            </div>
          </div>
        </div> */}

        <div className="container mb-5 pb-lg-4">
          <div className="row justify-content-center">
            <div className="col-lg-5 text-left text-lg-right px-4 p-lg-0 mt-4 order-12 order-lg-1">
              <h2 tabIndex={0}><b>SÓ COISA BOA!</b></h2>
              <h3 className="text-blue">VARIEDADE, QUALIDADE E CONFIANÇA</h3>
              <p className="font-size-1125 my-4"><b>Pode procurar que você vai achar o que precisa.</b> Afinal de contas, nossa variedade de categorias é
              enorme e o mix de produtos, completíssimo! Aqui, vamos de A a Z: desde alimentos, congelados e
              bebidas até produtos de limpeza, higiene e beleza.</p>
              <p className="font-size-1125 my-4">E não paramos por aí! Estamos também com as marcas Bonté (higiene pessoal), Baby Smile (de itens
              infantis), AS (ração para animais), Delicious (alimentos gourmet). Só coisa
              boa, né não?!</p>
            </div>
            <div className="col-lg-5 ml-lg-5 p-lg-0 pl-lg-4 justify-content-center px-4 order-1 order-lg-12">
              <GatsbyImage
                image={data.baby.childImageSharp.gatsbyImageData}
                className="dia-rounded w-100"
                alt="bebê com toalha e brinquedo" />
            </div>
          </div>
        </div>

        <div className="container mb-5">
          <div className="row justify-content-center">
            <div className="col-lg-5 mr-lg-5 p-lg-0 justify-content-center px-4 d-flex align-items-center">
              <GatsbyImage
                image={data.manifesto.childImageSharp.gatsbyImageData}
                className="dia-rounded w-100"
                alt="varios produtos alimentícios" />
            </div>
            <div className="col-lg-5 text-left px-4 mt-4 mt-lg-2">
              <h2 tabIndex={0}><b>... E, MODÉSTIA À PARTE,</b></h2>
              <h3 className="text-blue">SOMOS A MARCA PRÓPRIA MAIS VENDIDA NO BRASIL! \o/</h3>
              <p className="font-size-1125 my-4">Vê se não é para morrer de orgulho: em 2016, a Nielsen, empresa global de informações e pesquisa de
              mercado, <b>reconheceu a marca Dia como a mais vendida do Brasil.</b> Isso graças às nossas “Especialistas
              em Economia”, clientes Dia que prezam pelo próprio dinheiro e valorizam produtos qualidade, e ao
              momento de mercado.</p>
              <p className="font-size-1125 my-4">E, cá entre nós: este reconhecimento é prá lá de importante, pois comprova que todo o nosso
              investimento e cuidado com a marca faz sentido – e que podemos ser tão bons (e muitas vezes até
              melhores) que as marcas líderes do segmento. Uhuuuu!</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Produtos

export const query = graphql`{
  grafismo: file(relativePath: {eq: "produtos/Grafismo.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 488, height: 477, layout: CONSTRAINED)
    }
  }
  tapiok: file(relativePath: {eq: "produtos/produtos1.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 370, height: 340, layout: CONSTRAINED)
    }
  }
  mokochoko: file(relativePath: {eq: "produtos/MokoChoko.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 570, height: 470, layout: CONSTRAINED)
    }
  }
  papelperfumado: file(relativePath: {eq: "produtos/produtos3.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 570, height: 316, layout: CONSTRAINED)
    }
  }
  mapa: file(relativePath: {eq: "produtos/Group 410.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 249, height: 264, layout: CONSTRAINED)
    }
  }
  baby: file(relativePath: {eq: "produtos/image 87.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 470, height: 320, layout: CONSTRAINED)
    }
  }
  manifesto: file(relativePath: {eq: "produtos/imagem_manifesto.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 470, height: 526, layout: CONSTRAINED)
    }
  }
}
`
