import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import Swiper from 'react-id-swiper'
import { graphql, useStaticQuery } from 'gatsby'

const ProdutoCard = (props) => (
  <div className="col-auto d-flex justify-content-center py-5 p-2 border-radius-18 h-100">
    <div className="card card-produtos">
      <div className="p-0 position-relative d-flex justify-content-center align-items-end">
        <GatsbyImage image={props.image} alt="Produto Dia" className="w-100" />
      </div>
      <div className="card-body d-flex align-items-center ">
        <p className="card-text font-size-1125 font-weight-bold mx-auto ">{props.text}</p>
      </div>
    </div>
  </div>
)

const SectionLancamentos = () => {
  const data = useStaticQuery(
    graphql`{
  pipoca: file(relativePath: {eq: "produtos/Pipoca.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 270, height: 222, layout: CONSTRAINED)
    }
  }
  fralda: file(relativePath: {eq: "produtos/Fralda.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 270, height: 222, layout: CONSTRAINED)
    }
  }
  conserva: file(relativePath: {eq: "produtos/Conserva.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 270, height: 222, layout: CONSTRAINED)
    }
  }
  barbeador: file(relativePath: {eq: "produtos/Barbeador.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 270, height: 222, layout: CONSTRAINED)
    }
  }
}
`
  )

  const swiperParamsProdutos = {
    slidesPerView: 1,
    spaceBetween: 24,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      1000: {
        slidesPerView: 4,
        spaceBetween: 24
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 24
      }
    }
  }

  return (
    <div className="swiperProdutos">
      <h2 className="text-red text-center mb-0">DÁ SÓ UMA OLHADA NESTES LANÇAMENTOS!</h2>
      <div className="container">
        <div className="row">
          <Swiper {...swiperParamsProdutos}>
            <div>
              <ProdutoCard image={data.pipoca.childImageSharp.gatsbyImageData} text={ <>PIPOCAS DE MICRO-ONDAS DE-LÍ-CI-A!</> } />
            </div>
            <div>
              <ProdutoCard image={data.fralda.childImageSharp.gatsbyImageData} text={ <>FOFÍSSIMAS FRALDAS BABY SMILES!</> } />
            </div>
            <div>
              <ProdutoCard image={data.conserva.childImageSharp.gatsbyImageData} text={ <>LINHA EM CONSERVA QUE É SÓ ALEGRIA!</> } />
            </div>
            <div>
              <ProdutoCard image={data.barbeador.childImageSharp.gatsbyImageData} text={ <>LÂMINAS DE BARBEAR QUE SÃO UM ARRASO!</> } />
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default SectionLancamentos
